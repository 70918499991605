.project_feature_content {
  min-width: calc(9 * 100% /14);
  margin:  0rem  0rem  0rem  1.5rem;

  img {
    width: 100%;
    vertical-align: middle;
  }
}

.responsive-video{
    overflow: hidden;
    padding-bottom: 36.25%;
    position: relative;
    height: 0;

    iframe{
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }
}
