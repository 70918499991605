@import '../../sass/variables.scss';

.nav_bar {
  display: flex;
  flex-direction: row;
  border-bottom: 0.3rem solid $grey;
  padding: 0 0 1.3rem 0;
  margin: 0 0 1.1rem 0;

  .nav_bar__header_logo {
    width: 39.6rem;
    height: 3.6rem;
    vertical-align: bottom;
  }

  .nav_bar__pages {
      color: $grey;
      display: flex;
      line-height: 2rem;
      font-size: 2rem;
      flex-direction: row;
      align-items: flex-end;
      margin: 0 0 0 8.9rem;
      justify-content: space-evenly;
      width: 100%;

      .nav_bar__page:hover {
        color: $standard-bg-colour;
      }
  }

  input {
    width: 21rem;
    font-size: 2rem;
    align-self: flex-end;
  }
}
