@import '../../sass/variables.scss';

.project_text_column {
  margin-right: 0 !important;
//   margin:  0  1.5rem  0  0;
//   padding:  0  0  4rem  2rem;
//   background-color: $standard-bg-colour;
//
//   p, 1 {
//     margin:  4rem  0  0  0;
//   }
//
//   .project_text_column__bold {
//     font-weight: $font-weight-bold;
//   }
}
