.imprint {
 color: black;
 font-size: calc(10/12 * 1rem);
 line-height: 1.5;
}

.T2 {
 font-size: calc(16/12) * 1rem;
}

.P4 {
 margin-top: 2rem;
}

.P5 {
 font-size: 1rem;
}