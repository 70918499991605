.admin_form {
  font-size: 1.4rem;

  .form {
    margin: 1rem 1rem 1rem 1rem;

    h2 {
      font-size: 2.2rem;
    }

    h3 {
      font-size: 1.8rem;
    }
  }
}
