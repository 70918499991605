@import './sass/variables.scss';

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
  margin: 0;
  font-size: calc(100vw/144) !important; // calc(100vw/144 * 1) = 10px
  font-family: $font-family;
  // -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif
  font-weight: $font-weight-medium;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 5rem 4.8rem 6.6rem 4.8rem;
  color: $white;
  font-size: 2.6rem;
  line-height: 3rem;
  letter-spacing: 0rem;
}

footer {
  font-size: 1.2rem;
  line-height: 1.2rem;
  margin: 2.5rem 0rem 0rem 0rem;
  color: $grey;
  text-align: center;
}

input[type=text] {
  height: 2.9rem;
  font-size: 2.6rem;
  line-height: 3rem;
  margin: 0rem 0rem -0.45rem 0rem;
  padding: 0rem 0rem 0rem 0.9rem;
  background-color: $grey-bg-colour;
  border: 1px solid #818080;
}

h1 {
  font-weight: $font-weight-bold;
  font-size: 3rem;
}

h2 {
  font-weight: $font-weight-medium;
  font-size: 3rem;
}

ul {
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.row {
  display: flex;
  flex-direction: row;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
