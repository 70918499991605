// font colours

@font-face {
    font-family: 'Bryant-medium';
    src: url("../fonts/bryant_medium.ttf") format("truetype");
}

$white: #FFFFFF;
$dark-blue: #00121B;
$blue: #0099CC;
$grey: #787878;
// font styles
$font-family: "Bryant-medium";
//font weights
$font-weight-medium: 500;
$font-weight-bold: 700;
// surface colours
$standard-bg-colour: #0099CC;
$highlight-bg-colour: #1e1e1e;
$grey-bg-colour: #F0F0F0;
