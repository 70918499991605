.content_container {
  position: relative;
  width: 67.2rem;
  height: 37.8rem;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    position: absolute;
     left: 50%;
     top: 50%;
     transform: translate(-50%, -50%);
  }
}

.maps_wrapper{
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;

    iframe{
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }
}

.transparent {
  width: calc((100% / 3) - calc(100vw/144 * (2.8) / 3));
  margin: 0rem 0rem 1.4rem 0rem;
}
