.picture-rows {
  display: flex;
  flex-direction: row;
  margin-bottom: 0 !important;
  flex-wrap: wrap;

  img:nth-child(3n + 1) {
    margin-left: 0;
  }

  img {
    width: calc((100% / 3) - calc(100vw/144 * (2.8) / 3));
    margin: 0rem 0rem 1.4rem 1.4rem;
  }
}