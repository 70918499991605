@import '../../../../sass/variables.scss';
@import '../../../../sass/mixins.scss';

.content_overlay_wrapper {
  background: transparent;
  z-index: 1;

  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;


  .content_overlay {
    top: 10rem;
    $overlay-ratio: calc(100% * 550/672);
    position: relative;
    width: $overlay-ratio;
    max-height: 28rem;

    .content_overlay__header {
      padding-left: 1.6rem;
      border-bottom: 0.3rem solid $white;
    }

    .content_overlay__container {
      background: $standard-bg-colour;
      opacity: .85;
      margin: .5rem 0 0 0;
      padding: 1.6rem 1.8rem 1.6rem 1.8rem;

      span {
        @include lhCrop(3, 2.6, 4);
      }
    }

    .content_overlay__container:hover {
      background: $highlight-bg-colour;
    }

    .content_overlay__flag {
      position: sticky;
      transform: translate(60%, -50%);
      bottom: -4.3rem;
      right: -6rem;
      width: 20rem;
    }
  }

}
