.yellow {
  background-color: yellow !important;
}

.sidebar-item-text {
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}
