@import '../../../../sass/variables.scss';

.content_overlay_transparent_wrapper {
  background: transparent;
  z-index: 1;

  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  .content_overlay_transparent {
    position: relative;
    padding: 0 2rem 0 2rem;
    height: 50%;
  }
}
