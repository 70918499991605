@import '../../sass/variables.scss';

.swiper-centered {
  display: flex;
  justify-content: center;
}

.hidden {
  display: none;
}

.swiper, .project_content_gallery {
  position: relative;
  margin-left: -4.8rem;
  margin-right: -4.8rem;
  overflow-x: hidden;

  .navigation_arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translate(0, -50%);
    opacity: .65;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
  }

  .navigation_arrow:hover {
    opacity: 1;
    cursor: pointer;
  }

  .left_navigation_arrow {
    left: 0;
    margin-left: 4.8rem;
  }

  .right_navigation_arrow {
    right: 0;
    margin-right: 4.8rem;
  }

  .border_lines {
    border-top: .3rem solid $standard-bg-colour;
    border-bottom: .3rem solid $standard-bg-colour;
    height: 1rem;
  }

  .top {
    margin: 0 0 2.6rem 0;
  }

  .bottom {
    margin: 2.6rem  0  0  0;
  }
  
  .swiper-wrapper, .project_content_gallery__images {
    position: relative;
    display: flex;

    .swiper-slide:first-child {
      margin:  0  0  0  4.8rem;
    }

    .swiper-slide:last-child {
      margin-right: 4.8rem;
    }

    .swiper-slide {
      margin:  0  0  0  2.6rem;
      width: fit-content;
      
      .project_content_gallery__image {
        width:  57.6rem !important;
        height:  32.4rem !important;
        margin:  0  0  0  0;
      }
  
      .project_content_gallery__iframe {
        width:  57.6rem !important;
        height:  32.4rem !important;
        margin:  0  2.6rem  0  0;
  
        iframe {
          width:  57.6rem !important;
          height:  32.4rem !important;
        }
      }    
    }
  }
}
