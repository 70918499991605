@import '../../../../sass/variables.scss';
@import '../../../../sass/mixins.scss';

.content_overlay_transparent_big_wrapper {
  background: transparent;
  z-index: 1;

  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .content_overlay_transparent_big {
    $overlay-ratio: calc(100% * 550/672);
    position: relative;
    width: $overlay-ratio;
    max-height: 28rem;

    .content_overlay_transparent_big__header {
      border-bottom: 0px;
    }

    .content_overlay_transparent_big__container {
      background: transparent;
      opacity: .85;
      margin: .5rem 0 0 0;
      padding: 1.6rem 1.8rem 1.6rem 1.8rem;

      span {
        @include lhCrop(3, 2.6, 4);
      }
    }

    .content_overlay_transparent_big__container:hover {
      background: $highlight-bg-colour;
    }

    .content_overlay_transparent_big__flag {
      position: absolute;
      bottom: -4.3rem;
      right: -6rem;
      width: 20rem;
    }
  }
}
