@import '../../sass/variables.scss';

.project_page {
  .project_text_column {
    margin:  0rem  1.5rem  0rem  0rem;
    padding:  3.5rem  2rem  4rem  2rem;
    background-color: $standard-bg-colour;
    overflow: auto;

    p, h1 {
      margin:  0  0  1.3rem  0rem;
    }
  }

  .project_page__text_box {
    h1 {
      margin:  0  0  1.3rem  0rem;
    }
  }

  > * {
    margin-bottom:  1.5rem;
  }

  .border_lines {
    border-top:  .3rem solid $standard-bg-colour;
    border-bottom:  .3rem solid $standard-bg-colour;
    height:  1rem;
  }

  .project_page__text_box {
    line-height:  3.4rem;
  }

}
