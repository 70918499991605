.admin_sidebar {
  font-size: 1.8rem;
  color: black;
  width: 40rem;
  background-color: rgba(130, 124, 124, 0.75);
  position: fixed;
  top: 0rem;
  right: 0rem;
  max-height: 100vh;
  overflow-y: scroll;
  z-index: 10;
}

.sidebar-item-content {
  width: 100%;
}

.hidden {
  display: none;
}
